<template>
  <div id="LaTen">
    <template v-if="$route.name === 'LaTen'">
      <img
        v-if="$store.state.isMobile"
        class="bannerImg"
        src="@/assets/store/LatenBannerMobile.jpg"
        alt=""
      />
      <div class="MainContainer">
        <template v-if="!$store.state.isMobile">
          <div class="bannerBlock">
            <img
              src="@/assets/store/LatenBanner.jpg"
              class="banner"
              alt=""
            />
          </div>
        </template>
      </div>

      <div class="MainContainer">
        <ChooseType @click="ChooseTypeClick"></ChooseType>
      </div>
    </template>

    <router-view></router-view>
  </div>
</template>

<script>
  import base from './StoreBase.js';
  import ChooseType from '@/components/Store/ChooseType';
  export default {
    extends: base,
    name: 'LaTen',
    components: {
      ChooseType,
    },
    methods: {
      ChooseTypeClick(param) {
        this.$router.push({ name: 'LaTenStoreList', query: { type: param.name } });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import './index.scss';
  #LaTen {
    background: url('~@/assets/bg.png');
  }
</style>
